<div class="os-block-3 help-info">
  <div class="border-bottom os-bold font-22 py-3 px-4">Hulpopties</div>
  <div>
    <div
      *ngIf="isTeacher"
      class="d-flex align-items-center os-link-dark py-3 px-4 border-bottom"
      (click)="onGuideClicked()"
    >
      <i class="fa-regular fa-file-pdf font-18 me-2"></i>
      Handleiding
    </div>
    <div
      class="d-flex align-items-center os-link-dark py-3 px-4 border-bottom"
      (click)="onOnboardingVideosClicked()"
    >
      <i class="fa-regular fa-circle-play font-18 me-2"></i>
      Uitlegvideo's
    </div>
    <div
      class="d-flex align-items-center os-link-dark py-3 px-4 border-bottom"
      (click)="onFaqClicked()"
    >
      <i class="fa-solid fa-question font-18 me-2"></i>
      Veelgestelde vragen
    </div>
    <div
      class="d-flex align-items-center os-link-dark py-3 px-4 mb-1"
      (click)="onContactClicked()"
    >
      <i class="fa-solid fa-phone font-18 me-2"></i>
      Contact
    </div>
  </div>
</div>
