import { Component, Input, OnInit } from "@angular/core";
import { OnboardingService } from "@app/shared/services/onboarding.service";
import { UtilService } from "@app/shared/services/util.service";
import { UserExtended } from "@app/shared/models/entities/user/UserExtended";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  selector: "navbar-help",
  templateUrl: "./help.component.html",
  styleUrls: ["./help.component.scss"],
  imports: [CommonModule],
})
export class HelpComponent implements OnInit {
  @Input() userExtended!: UserExtended;

  protected isTeacher: boolean = false;

  constructor(
    private onboardingService: OnboardingService,
    private utilService: UtilService
  ) {}

  ngOnInit(): void {
    this.isTeacher = this.utilService.isTeacher(this.userExtended);
  }

  protected onOnboardingVideosClicked() {
    this.onboardingService.openOnboardingVideoModal(this.userExtended);
  }

  protected onFaqClicked() {
    if (this.isTeacher) {
      window.open("https://onlineslagen.nl/scholen#faq", "_blank");
    } else {
      window.open("https://www.onlineslagen.nl/examenboekjes-voor-leerlingen/", "_blank");
    }
  }

  protected onContactClicked() {
    window.open("https://onlineslagen.nl/contact", "_blank");
  }

  protected onGuideClicked() {
    window.open(
      "https://os-file-storage.ams3.cdn.digitaloceanspaces.com/media/uploads/onboarding/Handleiding%20Docentendashboard%20Onlineslagen.pdf",
      "_blank"
    );
    // if (this.userExtended.role === OSRoles.DemoTeacher) {
    // } else {
    //   window.open("https://onlineslagen.nl/scholen#faq", "_blank");
    // }
  }
}
