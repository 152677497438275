export enum Place {
  Home = 0,
  Training = 1,
  Course = 2,
  Domain = 3,
  Account = 4,
  Webshop = 5,
  StudentDashboard = 7,
  Feedback = 8,
  TrainingConcepts = 9,
  TrainingTheories = 10,
  TrainingNotes = 11,
  Inbox = 15,
  Search = 16,
  TheoryBook = 17,
  Flashcards = 18,
  Settings = 19,
  Exams = 20,
  ExamStart = 21,
  Exam = 22,
  ExamReport = 23,
  ExamReview = 24,
  ExamenBundle = 30,
  ExamQRLandingPage = 31,
  FreeCourse = 35,
  FreeTraining = 36,
  TeacherDashboard = 40,
  TeacherDashboardReport = 41,
  TeacherDashboardStudentTraining = 42,
  TeacherDashboardStudentCourseReview = 43,
  TeacherDashboardStudentExam = 44,
  TeacherDashboardTasks = 45,
  TeacherDashboardNewTask = 46,
  TeacherDashboardTasksArchive = 47,
  TeacherDashboardTaskStudent = 48,
  TeacherDashboardTaskStudentReview = 49,
  TeacherDashboardGroup = 50,
  TeacherDashboardSettings = 51,
  TeacherDashboardIntroduction = 52,
  TeacherDashboardStudentExamReview = 53,
  DDTrainingProgress = 60,
  DDTrainingMaterials = 61,
  DDTrainingGroup = 63,
  DDTrainingTasks = 65,
  CreateTask = 66,
  EditTask = 67,
  DDHome = 68,
  DDCourse = 70,
  DDExam = 80,
  Onboarding = 90,
  NotFound = 999,
}
