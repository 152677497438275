<div class="os-block-3 user-info p-4 d-none d-md-block">
  <div *ngIf="!isLoading">
    <a
      class="d-flex gap-3 align-items-center border-bottom pb-3 mb-3 text-decoration-none"
      routerLink="/gebruiker/{{ user.id }}/profiel"
    >
      <div class="flex-center-center">
        <shared-avatar
          size="medium-large"
          [avatarUrl]="user.avatar?.url"
          [userName]="user.first_name"
        ></shared-avatar>
      </div>
      <div>
        <p class="os-text-dark os-bold font-22 mb-0">{{ user.first_name }} {{ user.last_name }}</p>
        <small class="d-block os-text-lightgrey mb-2">
          {{ user.email }}
        </small>
      </div>
    </a>
    <div
      *ngIf="user.level as userLevel"
      class="d-flex align-items-center gap-4 border-bottom pb-3"
    >
      <div class="flex-grow-1">
        <div class="d-flex justify-content-between align-items-start font-18 mb-1">
          <div class="os-bold">Level {{ userLevel.level }}</div>

          <div class="font-14 os-text-lightgrey">
            {{ userLevel.xp_required - userLevel.xp }} XP tot level
            {{ userLevel.level + 1 }}
          </div>
        </div>
        <progress-bar
          [achieved]="userLevel.xp"
          [potential]="userLevel.xp_required"
        ></progress-bar>
      </div>
    </div>
    <div class="os-text-dark border-bottom py-3">
      <a
        class="d-flex align-items-center mb-3 os-link-dark"
        routerLink="/gebruiker/{{ user.id }}/profiel"
      >
        <i class="fa-solid fa-user font-18 me-3"></i>
        Mijn profiel
      </a>
      <a
        class="d-flex align-items-center mb-3 os-link-dark"
        routerLink="/home/account-instellen/"
      >
        <i class="fa-solid fa-gear font-18 me-3"></i>
        Vakken instellen
      </a>
      <a
        *ngIf="isSchool24 && user.isStudent && !user.isSchoolStudent"
        class="d-flex align-items-center gap-3 mb-3 os-link-dark"
        (click)="onMyPackageClicked()"
      >
        <i class="fa-solid fa-box font-18"></i>
        <div>Mijn pakket</div>
        <div
          class="os-text-white os-background-blue br-6 os-semi-bold"
          style="padding: 1px 12px"
        >
          {{ hasActiveSubscription ? "Premium" : "Basis" }}
        </div>
      </a>
      <a
        class="d-flex align-items-center os-link-dark"
        routerLink="/inbox/"
      >
        <i class="fa-solid fa-envelope font-18 me-3"></i>
        Inbox
      </a>
    </div>
    <div class="d-flex align-items-center os-link-dark pt-3">
      <i class="fa-solid fa-right-from-bracket font-18 me-3"></i>
      <a (click)="logout()">Uitloggen</a>
    </div>
  </div>

  <loading-spinner *ngIf="isLoading"></loading-spinner>
</div>
