import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AuthGuardService as AuthGuard } from "./auth/auth-guard.service";
import { AuthGuardAdminService as AuthGuardAdmin } from "@app/auth/auth-guard-admin.service";

import { NoAdminAccessComponent, NotFoundComponent } from "@app/shared/feature-components/error";
import { DominosComponent } from "./school/partners/dominos/dominos.component";
import { DeRooiPannenComponent } from "./school/partners/derooipannen/derooipannen.component";
import { LidlComponent } from "./school/partners/lidl/lidl.component";
import { AhComponent } from "./school/partners/ah/ah.component";
import { FranchisersComponent } from "./school/partners/franchisers/franchisers.component";
import { AttachmentPageComponent, FilePageComponent } from "@app/shared/feature-components/upload";
import { AhCompleteAccountComponent } from "./school/partners/ah-complete-account/ah-complete-account.component";
import { ExistingUserGuard } from "@app/user-onboarding/guards/existing-user.guard";
import { NewUserGuard } from "@app/user-onboarding/guards/new-user.guard";
import { ExamQRLandingComponent } from "./training/training-page/onlineslagen/components/exams/exam-qr-landing/exam-qr-landing.component";

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "onboarding",
    loadComponent: () => import("./user-onboarding/onboarding.component").then((m) => m.OnboardingComponent),
    canActivate: [ExistingUserGuard],
  },
  {
    path: "demo-docent",
    redirectTo: "auth/demo-docent",
  },
  {
    path: "winkel",
    loadChildren: () => import("./shops/subscriptions/subscriptions.module").then((m) => m.SubscriptionsModule),
  },
  {
    path: "boekenwinkel",
    loadChildren: () => import("./shops/books/books.module").then((m) => m.BooksModule),
  },
  {
    path: "examentrainingen",
    loadChildren: () => import("./shops/exam-trainings/exam-trainings.module").then((m) => m.ExamTrainingsShopModule),
  },
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "home",
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
    canActivate: [AuthGuard, NewUserGuard],
  },
  {
    path: "training/:trainingId",
    loadChildren: () => import("./training/training.module").then((m) => m.TrainingModule),
    canActivate: [AuthGuard],
  },
  {
    path: "training/:trainingId/klas/:groupId",
    loadChildren: () => import("./training/training.module").then((m) => m.TrainingModule),
    canActivate: [AuthGuard],
  },
  {
    path: "vak/:trainingId",
    loadChildren: () => import("./training/training.module").then((m) => m.TrainingModule),
    canActivate: [AuthGuard],
  },
  {
    path: "vak/:trainingId/klas/:groupId",
    loadChildren: () => import("./training/training.module").then((m) => m.TrainingModule),
    canActivate: [AuthGuard],
  },
  {
    path: "samenvattingen/vak/:trainingName/:trainingLevel/:trainingPhase/:trainingId",
    loadChildren: () => import("./training/training.module").then((m) => m.TrainingModule),
  },
  {
    path: "beheer",
    loadChildren: () => import("./management/management.module").then((m) => m.ManagementModule),
    canActivate: [AuthGuardAdmin],
  },
  {
    path: "ah",
    component: AhComponent,
  },
  {
    path: "ah-account-afmaken",
    component: AhCompleteAccountComponent,
  },
  {
    path: "ah-franchise",
    component: FranchisersComponent,
  },
  {
    path: "derooipannen",
    component: DeRooiPannenComponent,
  },
  {
    path: "lidl",
    component: LidlComponent,
  },
  {
    path: "dominos",
    component: DominosComponent,
  },
  {
    path: "gebruiker",
    loadChildren: () => import("./user/user.module").then((m) => m.UserModule),
    canActivate: [AuthGuard],
  },
  {
    path: "oude-examens/:old-exam-id/score-per-onderdeel-calculator",
    component: ExamQRLandingComponent,
  },
  {
    path: "feedback/:id",
    loadChildren: () => import("./feedback/feedback.module").then((m) => m.FeedbackModule),
    canActivate: [AuthGuard],
  },
  {
    path: "scholen",
    loadChildren: () => import("./school/school.module").then((m) => m.SchoolModule),
  },
  {
    path: "docentendashboard",
    loadChildren: () => import("./teachers/teachers.module").then((m) => m.TeachersModule),
  },
  {
    path: "no-admin-access",
    component: NoAdminAccessComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "inbox",
    loadChildren: () => import("./inbox/inbox.module").then((m) => m.InboxModule),
  },
  {
    path: "examenbuddy",
    loadChildren: () => import("./examenbuddy/examenbuddy.module").then((m) => m.ExamenBuddyModule),
  },
  {
    path: "examenbundel",
    loadChildren: () => import("./exambundle/exambundle.module").then((m) => m.ExamBundleModule),
    canActivate: [AuthGuard],
  },
  {
    path: "forum",
    loadChildren: () => import("./central-forum/central-forum-routing.module").then((m) => m.CentralForumRoutingModule),
  },
  {
    path: "bijlage/:attachment-id",
    component: AttachmentPageComponent,
  },
  {
    path: "bestand/:file-id",
    component: FilePageComponent,
  },
  {
    path: "**",
    component: NotFoundComponent,
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
      anchorScrolling: "enabled",
      scrollPositionRestoration: "disabled",
      scrollOffset: [0, 64],
    }),
  ],
})
export class AppRoutingModule {}
