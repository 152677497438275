import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router, RouterModule } from "@angular/router";

import { CookieService } from "ngx-cookie-service";

import { UserService } from "@app/shared/services/user.service";
import { OnboardingService } from "@app/shared/services/onboarding.service";
import { UtilService } from "@app/shared/services/util.service";
import { InboxService } from "@app/inbox/inbox.service";

import { UserThread } from "@app/inbox/models/UserThread";
import { UserAction } from "@app/models/onboarding/UserAction";
import { Category } from "@app/models/onboarding/Category";
import { OSRoles } from "@app/shared/enums/user-type-enum";
import { CommonModule } from "@angular/common";
import { ChecklistsComponent } from "@app/shared/feature-components/navbar/components/checklists/checklists.component";
import { UserInfoComponent } from "@app/shared/feature-components/navbar/components/user/user.component";
import { HelpComponent } from "@app/shared/feature-components/navbar/components/help/help.component";
import { ClickOutsideDirective } from "@app/shared/directives/click-outside.directive";
import { MenuItem } from "@app/shared/models/MenuItem";
import { AvatarComponent } from "@app/shared/basic-components";
import { finalize, of, zip } from "rxjs";
import { User } from "@app/shared/models/entities/user/User";

interface NavbarMenuItem extends Omit<MenuItem, "key"> {}

@Component({
  standalone: true,
  selector: "shared-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ChecklistsComponent, UserInfoComponent, HelpComponent, RouterModule, ClickOutsideDirective, AvatarComponent],
})
export class NavbarComponent implements OnInit {
  UserRole: typeof OSRoles = OSRoles;
  user!: User;
  simplifiedRole!: "demo_leerling" | "leerling" | "demo_docent" | "docent";

  userInfoToggled!: boolean;
  helpToggled!: boolean;
  checkListsToggled!: boolean;

  newMessages: number = 0;
  userThreads!: UserThread[];
  userActions!: UserAction[];
  categories!: Category[];

  nrTotalActions: number = 0;
  nrFinishedActions: number = 0;
  nrOpenActions: number = 0;

  isSchool24: boolean = UtilService.isSchool24();
  showChecklist: boolean = false;
  currentUrl!: string;

  mainMenuItems!: NavbarMenuItem[];
  adminMenuItems!: NavbarMenuItem[];

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private inboxService: InboxService,
    private userService: UserService,
    private onboardingService: OnboardingService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.getUser();
  }

  getUser() {
    this.userService
      .getUser()
      .pipe(finalize(() => this.cdr.markForCheck()))
      .subscribe((user) => {
        this.user = User.Factory(user);
        this.setMenuItems();

        // Ensure that navbar http calls are done after regular http calls in other components.
        setTimeout(() => {
          this.getData();
        }, 150);
      });
  }

  private getData() {
    const userThreadParams = {
      participants: this.user.id,
      mode: "simple",
    };

    const checkListParams = {
      parent: true,
      user_role: this.user.user_extended?.role,
      product: UtilService.getProductName().toLowerCase(),
    };
    zip([
      this.inboxService.getUserThreads(userThreadParams),
      this.shouldShowChecklist()
        ? this.onboardingService.getUserActions({
            user_role: this.user.user_extended?.role,
          })
        : of(null),
      this.shouldShowChecklist() ? this.onboardingService.getCheckLists(checkListParams) : of(null),
    ]).subscribe({
      next: ([userThreads, userActions, categories]) => {
        this.userThreads = userThreads;
        this.newMessages = this.userThreads.filter((thread) => thread.has_unread_messages).length;
        if (userActions) {
          this.userActions = userActions;
          this.checkListsToggled = this.userActions.some((userAction) => userAction.is_new);
          this.nrFinishedActions = this.userActions.length;
        }

        if (categories && categories.length > 0) {
          this.showChecklist = true;
          this.categories = categories;
          this.calculateTotalActions(categories);
          setTimeout(() => {
            this.nrOpenActions = this.nrTotalActions - this.nrFinishedActions;
          }, 100);
        }
      },
    });
  }

  private shouldShowChecklist() {
    return (
      (this.user.user_extended?.role === OSRoles.DemoTeacher ||
        this.user.user_extended?.role === OSRoles.Teacher ||
        this.user.user_extended?.role === OSRoles.Student ||
        this.user.user_extended?.role === OSRoles.CustomerStudent) &&
      !UtilService.isSchool24()
    );
  }

  private calculateTotalActions(categories: Category[]): number {
    const product = UtilService.getProductName().toLowerCase();
    return categories?.reduce(
      (total, category) =>
        total +
        (category.product === "any" || category.product === product
          ? category.actions.length + this.calculateTotalActions(category.children)
          : 0),
      0
    );
  }

  onNewCheckListData(data: { nrFinishedActions: number; nrTotalActions: number }) {
    this.nrFinishedActions = data.nrFinishedActions;
    this.nrTotalActions = data.nrTotalActions;
    this.nrOpenActions = this.nrTotalActions - this.nrFinishedActions;
  }

  setMenuItems() {
    this.mainMenuItems = [
      {
        title: UtilService.isSchool24() ? "Vakken" : "Examentrainingen",
        url: "/home/vakken",
        faIcon: "fa fa-home",
      },
    ];
    if (!UtilService.isSchool24()) {
      this.mainMenuItems.push({
        title: "Examenbundel",
        url: "/examenbundel",
        faIcon: "fas fa-list",
      });
    }
    this.mainMenuItems.push(
      { title: "Forum", url: "/forum", faIcon: "fa fa-comments" },
      {
        title: this.user.isTeacher ? "Docentendashboard" : "Mijn school",
        url: this.user.isTeacher ? "/scholen/leraren" : "/scholen/leerlingen",
        faIcon: this.user.isTeacher ? "fa fa-bar-chart" : "fas fa-school",
      }
    );

    this.mainMenuItems.push({
      title: "Inbox",
      url: "/inbox",
      faIcon: "fa fa-envelope-o",
    });

    this.mainMenuItems.push({
      title: "Vakken instellen",
      url: "/home/account-instellen",
      faIcon: "fa fa-cog",
    });

    this.adminMenuItems = [
      {
        title: "Beheer",
        url: "/beheer/vakken",
        faIcon: "fas fa-cubes",
      },
    ];
  }

  onOutsideChecklistClicked(event: MouseEvent) {
    const htmlElement = event.target as HTMLElement;
    const checkListIcon = document.getElementById("checklist-icon") as HTMLElement;

    const checkListIconMobile = document.getElementById("checklist-icon-mobile") as HTMLElement;

    if (!checkListIcon.contains(htmlElement) && !checkListIconMobile?.contains(htmlElement) && !htmlElement.classList.contains("fade")) {
      this.checkListsToggled = false;
    }
  }

  onCloseClicked() {
    this.checkListsToggled = false;
  }

  onOutsideHelpClicked(event: MouseEvent) {
    const helpIcon = document.getElementById("help-icon") as HTMLElement;
    if (!helpIcon.contains(event.target as Node)) {
      this.helpToggled = false;
    }
  }

  onOutsideUserInfoClicked(event: MouseEvent) {
    const checkListIcon = document.getElementById("user-icon") as HTMLElement;
    if (!checkListIcon.contains(event.target as Node)) {
      this.userInfoToggled = false;
    }
  }

  onItemClicked(item: MenuItem) {
    document.getElementsByTagName("body")[0].style.overflow = "auto";
    item.url && this.router.navigateByUrl(item.url);
  }

  onMyAccountClicked() {
    if (UtilService.isSchool24()) {
      this.router.navigate(["gebruiker", "account"]);
    } else {
      window.open("https://www.onlineslagen.nl/mijn-account/edit-account/", "_blank");
    }
  }

  logout() {
    (window as any).fcWidget.destroy();
    this.cookieService.delete("token", "/");
    this.redirectToLogin();
  }

  redirectToLogin() {
    this.router.navigateByUrl("/auth");
  }
}
