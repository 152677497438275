import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router, RouterLink } from "@angular/router";

import { CookieService } from "ngx-cookie-service";

import { CommonModule } from "@angular/common";
import { AvatarComponent, LoadingSpinnerComponent, ProgressBarComponent } from "@app/shared/basic-components";
import { User } from "@app/shared/models/entities/user/User";
import { UserService } from "@app/shared/services/user.service";
import { UtilService } from "@app/shared/services/util.service";
import { ShopService } from "@app/shared/services/shop.service";
import { Subscription } from "@app/shops/models/Subscription";
import { S24PremiumModalComponent } from "@app/shared/feature-components/modals";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  standalone: true,
  selector: "navbar-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
  imports: [CommonModule, RouterLink, AvatarComponent, ProgressBarComponent, LoadingSpinnerComponent],
})
export class UserInfoComponent implements OnInit {
  protected user!: User;
  protected isLoading!: boolean;
  protected isSchool24!: boolean;

  private subscriptions!: Subscription[];
  protected hasActiveSubscription!: boolean;

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private userService: UserService,
    private shopService: ShopService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.isSchool24 = UtilService.isSchool24();
    this.getUser();
  }

  private getUser() {
    this.userService.clearState();
    this.isLoading = true;
    this.userService.getUser().subscribe({
      next: (user) => {
        this.user = user;
        this.getSubscriptions();
      },
    });
  }

  private getSubscriptions() {
    this.shopService.getUserSubscriptions().subscribe({
      next: (subscriptions) => {
        this.subscriptions = subscriptions;
        this.setHasActiveSubscription();
        this.isLoading = false;
        this.cdr.markForCheck();
      },
    });
  }

  private setHasActiveSubscription() {
    this.hasActiveSubscription = this.subscriptions.some((sub) => sub.isActive);
  }

  protected onMyPackageClicked(): void {
    if (this.hasActiveSubscription) {
      this.router.navigate(["gebruiker", "account"], {
        queryParams: { tab: "subscriptions" },
      });
    } else {
      this.onOpenPremiumModal();
    }
  }

  private onOpenPremiumModal(): void {
    this.modalService.open(S24PremiumModalComponent, {
      size: "l",
      backdropClass: "os-backdrop-blurred",
      animation: false,
    });
  }

  logout() {
    (window as any).fcWidget.destroy();
    this.cookieService.delete("token", "/");
    this.router.navigateByUrl("/auth");
  }
}
